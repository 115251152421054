import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  avatar: {
    ...theme.typography.body2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  image: {
    borderRadius: '50%',
    background: theme.palette.background.default,

    [theme.breakpoints.down('xs')]: {
      width: 90,
      height: 90,
    },
  },

  initial: {
    ...theme.typography.h5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    textTransform: 'uppercase',
    borderRadius: '50%',
    background: theme.palette.grey['200'],
  },

  name: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(11),
    margin: theme.spacing(2, 0, 0),
  },
}));

const Avatar = ({ image, name, size }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatar}>
      {
        image ? (
          <img className={classes.image} alt={name} src={image} width={size} height={size} />
        ) : (
          <div className={classes.initial}>{name.slice(0, 1)}</div>
        )
      }
      <p className={classes.name}>{name}</p>
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 100,
  image: null,
};

export default Avatar;
