import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Avatar from './Avatar';

const useStyles = makeStyles(theme => ({
  speakersList: {
    display: 'inline-flex',
    overflowX: 'auto',
    maxWidth: '100%',

    '& > *': {
      margin: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'visible',
      justifyContent: 'center',
    },
  },
}));

const SpeakersList = ({ data, ...props }) => {
  const classes = useStyles();

  return (
    <div {...props}>
      <div className={classes.speakersList}>
        {
          data.map(({ name, image, activeMic, size }) => (
            <div key={`${name}${image}`} style={{ opacity: activeMic ? 1 : 0.5 }}>
              <Avatar name={name} image={image} size={size} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

SpeakersList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    size: PropTypes.number,
  })).isRequired
};

export default SpeakersList;
