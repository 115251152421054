import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import PauseIcon from '@material-ui/icons/PauseRounded';
import classnames from 'classnames';
import audioService from '../../services/audioService';
import { formatTime } from '../../services/utils';

const TIME_MODES = {
  TOTAL: 'total',
  REMAINING: 'remaining',
};

const useStyles = makeStyles(theme => ({
  audioPlayer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 380,
  },

  /* PLAY BUTTON STYLES */
  playButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    outline: 'none',
    color: '#fff',
    borderWidth: 0,
    background: 'linear-gradient(to right, #f45501, #ff0402)',
    borderRadius: '50%',
  },

  /* PROGRESS BAR STYLES */
  progress: {
    ...theme.typography.body2,
    position: 'relative',
    marginLeft: 20,
    width: '100%',
    userSelect: 'none',
  },
  progressBarOuter: {
    position: 'relative',
    width: '100%',
    height: 4,
    outline: 'none',
    cursor: 'pointer',

    /*
      allows getting click/touch events from the progress
      bar outer div, not from child elements inside of it
    */
    '& *': {
      pointerEvents: 'none',
    },

    /*
      increases click/touch area of the progress bar
    */
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: 7,
    },

    '&:before': {
      top: '100%',
    },

    '&:after': {
      bottom: '100%',
    },
  },
  progressBarInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.grey['300'],
    borderRadius: 2,
    overflow: 'hidden',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
  currentProgressBar: {
    background: theme.palette.grey['600'],
  },
  bufferedProgressBar: {
    height: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
  },

  /* TIME STYLES */
  time: {
    position: 'absolute',
    top: 9,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(9),
  },
  currentTime: {
    left: 0,
  },
  totalTime: {
    right: 0,
    cursor: 'pointer',
    pointerEvents: 'initial',
    outline: 'none',
  },
}));

const AudioPlayer = ({ src, onAudioAnalysis, className, ...props }) => {
  const classes = useStyles();

  /* STATE */
  const [isReady, setReadyState] = useState(false);
  const [paused, setPaused] = useState(true);
  const [position, setPosition] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [bufferedRanges, setBufferedRanges] = useState([]);
  const [timeMode, setTimeMode] = useState(TIME_MODES.TOTAL);

  /* REFS */
  // holds an object that provides information
  // and allows controlling the currently played audio
  const audioRef = useRef({});

  // holds an object that provides information
  // about seeking state. if 'active' is true then
  // the user is seeking and 'position' refers to
  // the target position while seeking. if 'active'
  // is false then 'position' is null.
  const seekingRef = useRef({ active: false, position: null });

  /* FUNCTIONS */
  const setPositionValue = (value) => {
    setPosition(Math.max(0, Math.min(1, value)));
  };

  const seekTo = useCallback((time) => {
    const targetTime = Math.max(0, Math.min(totalTime, time));
    setPositionValue(targetTime / totalTime);
    setCurrentTime(targetTime);
    audioRef.current.seek(targetTime);
  }, [totalTime]);

  const setBufferedRangesValue = (timeRanges) => {
    let n = 0;
    const values = [];

    while (timeRanges.length > n) {
      values.push([timeRanges.start(n), timeRanges.end(n)]);
      n += 1;
    }

    setBufferedRanges(values);
  };

  const togglePlayState = useCallback(() => {
    if (paused) {
      if (audioRef.current.isEnded()) setPositionValue(0);
      audioRef.current.play();
      setPaused(false);
    } else
    if (isReady) {
      audioRef.current.pause();
      setPaused(true);
    }
  }, [paused, isReady]);

  /* EFFECTS */
  useEffect(() => {
    audioRef.current = audioService.playAudio({
      src,
      onReady: () => !isReady && setReadyState(true),
      onAnalysis: onAudioAnalysis,
      onProgress: timeRanges => setBufferedRangesValue(timeRanges),
      onTimeUpdate: time => setCurrentTime(time),
      onMetaData: time => setTotalTime(time),
      onEnded: () => setPaused(true),
    });

    return () => {
      audioRef.current.close();
    };
  }, [src, isReady, onAudioAnalysis]);

  useEffect(() => {
    const keyboardJumpSeconds = 5;

    const onKeyboardInput = (e) => {
      switch (e.keyCode) {
        // Space
        case 32:
          togglePlayState();
          e.preventDefault();
          break;

        // ArrowRight
        case 39:
          seekTo(currentTime + keyboardJumpSeconds);
          e.preventDefault();
          break;

        // ArrowLeft
        case 37:
          seekTo(currentTime - keyboardJumpSeconds);
          e.preventDefault();
          break;

        default:
      }
    };

    window.addEventListener('keydown', onKeyboardInput);

    return () => {
      window.removeEventListener('keydown', onKeyboardInput);
    };
  }, [togglePlayState, seekTo, currentTime]);

  /* RENDER */
  const renderPlayButton = () => {
    const title = paused ? 'Play' : 'Pause';
    const ButtonIcon = paused ? PlayIcon : PauseIcon;

    return (
      <IconButton
        title={title}
        className={classes.playButton}
        onClick={togglePlayState}
      >
        <ButtonIcon fontSize="small" />
      </IconButton>
    );
  };

  const renderProgressBar = () => {
    const onProgressBarMouseDown = ({ nativeEvent: mouseDownEvent }) => {
      const { offsetX, pageX, target } = mouseDownEvent;
      const progressBarWidth = target.offsetWidth;
      const positionOnClick = offsetX / progressBarWidth;

      seekingRef.current.active = true;
      seekingRef.current = {
        active: true,
        position: positionOnClick,
      };

      setPositionValue(seekingRef.current.position);

      const onMouseMove = (mouseMoveEvent) => {
        seekingRef.current.position = positionOnClick + (mouseMoveEvent.pageX - pageX) / progressBarWidth;
        setPositionValue(seekingRef.current.position);
      };

      const onMouseUp = () => {
        seekTo(seekingRef.current.position * totalTime);

        seekingRef.current = {
          active: false,
          position: null,
        };
        
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      };

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    };

    const toggleTimeMode = () => {
      setTimeMode(timeMode === TIME_MODES.TOTAL ? TIME_MODES.REMAINING : TIME_MODES.TOTAL);
    }

    const elapsedTime = seekingRef.current.active ? seekingRef.current.position * totalTime : currentTime;
    const remainingTime = totalTime - elapsedTime;

    return (
      <div className={classes.progress}>
        <div
          className={classes.progressBarOuter}
          role="button"
          tabIndex="0"
          onMouseDown={onProgressBarMouseDown}
        >
          <div className={classes.progressBarInner}>
            <div
              className={classnames(
                classes.progressBar,
                classes.currentProgressBar,
              )}
              style={{ width: `${100 * position}%` }}
            />

            {
              totalTime && bufferedRanges.map(([start, end]) => (
                <div
                  key={`${start}${end}`}
                  className={classnames(
                    classes.progressBar,
                    classes.bufferedProgressBar,
                  )}
                  style={{
                    left: `${start * 100 / totalTime}%`,
                    width: `${(end - start) * 100 / totalTime}%`,
                  }}
                />
              ))
            }
          </div>
        </div>

        <div className={classnames(classes.time, classes.currentTime)}>
          <time>{formatTime(elapsedTime)}</time>
        </div>

        <div
          className={classnames(classes.time, classes.totalTime)}
          role="button"
          tabIndex="0"
          onMouseDown={toggleTimeMode}
        >
          <time>{timeMode === TIME_MODES.TOTAL ? formatTime(totalTime) : `-${formatTime(remainingTime)}`}</time>
        </div>
      </div>
    );
  };

  return (
    <div className={classnames(className, classes.audioPlayer)} {...props}>
      {renderPlayButton()}
      {renderProgressBar()}
    </div>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  onAudioAnalysis: PropTypes.func,
  className: PropTypes.string,
};

AudioPlayer.defaultProps = {
  className: '',
  onAudioAnalysis: () => null,
};

export default AudioPlayer;
