export default {
  setInputDevice(id) {
    if (this.localStream && id) {
      this.inputDeviceId = id;
      this.localStream.switchDevice('audio', id);
    }
  },

  setOutputDevice(id) {
    if (this.remoteStream && id) {
      this.outputDeviceId = id;
      this.remoteStream.setAudioOutput(id);
    }
  },

  mute() {
    this.localStream?.disableAudio();
  },

  unmute() {
    this.localStream?.enableAudio();
  },

  connect({
    channel,
    uid,
    inputDeviceId,
    outputDeviceId,
    role = 'host',
    getAudioLevel = () => null,
  }) {
    return new Promise(async (resolve, reject) => {
      const Agora = await import('agora-rtc-sdk');

      this.client = Agora.createClient({
        mode: 'live',
        codec: 'h264',
        areaCode: [Agora.AREAS.GLOBAL]
      });

      this.client.init(process.env.GATSBY_AGORA_APP_KEY, () => {
        this.connected = true;
        this.client.setClientRole(role);

        this.client.join(null, channel, null, (agoraUID) => {
          if (role === 'host') {
            // local stream
            this.localStream = Agora.createStream({
              streamID: uid ?? agoraUID,
              audio: true,
              video: false,
              screen: false,
            });

            this.localStream.init(() => {
              this.client.publish(this.localStream, reject);
            }, reject);

            if (inputDeviceId) {
              this.setInputDevice(inputDeviceId);
            }
          }

          // remote stream
          this.client.on('stream-added', (e) => {
            this.remoteStream = e.stream;

            const id = this.remoteStream.getId();

            if (id !== uid) {
              this.client.on('stream-subscribed', (e) => {
                const div = document.createElement('div');
                div.id = 'local_stream';
                document.body.appendChild(div);
                e.stream.play('local_stream');
                this.audioLevelInterval = setInterval(() => {
                  getAudioLevel(e.stream.getAudioLevel());
                }, 10)
              })

              this.client.subscribe(this.remoteStream, reject);

              if (outputDeviceId) {
                this.setOutputDevice(outputDeviceId);
              }
            }
          });
        }, reject);
      }, reject);
    });
  },

  disconnect() {
    if (this.client) {
      if (this.localStream) this.client.unpublish(this.localStream);
      if (this.remoteStream) this.client.unsubscribe(this.remoteStream);
      clearInterval(this.audioLevelInterval);
      this.client.leave();
      this.connected = false;
    }
  },
}
