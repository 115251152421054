export const leadZero = (value) => {
  if (value < 10) return `0${value}`;
  return value;
};

export const formatTime = (value, formatter) => {
  const time = Math.floor(value);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor((time - (hours * 60 * 60)) / 60);
  const seconds = Math.floor(time - (hours * 60 * 60) - (minutes * 60));

  if (typeof formatter === 'function') {
    return formatter({ value, time, hours, minutes, seconds });
  }

  return (
    `${leadZero(hours)}:${leadZero(minutes)}:${leadZero(seconds)}`
  );
}
