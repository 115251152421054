

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { Container, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Divider from '../components/Divider';
import SpeakersList from '../components/SpeakersList';
import AudioPlayer from '../components/AudioPlayer';
import agoraService from '../services/agoraService';
import dataService from '../services/dataService';

const ROOM_STATUS = {
  LIVE: 'live',
  ENDED: 'ended',
  OFFLINE: 'offline',
  BROKEN: 'broken',
};

const useStyles = makeStyles(theme => ({
  roomView: {
    margin: theme.spacing(12, 'auto', 2),
    textAlign: 'center',
  },
  roomIcon: {
    position: 'relative',
    width: 76,
    height: 76,
    margin: theme.spacing(0, 'auto', 2),

    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      borderWidth: 4,
      borderStyle: 'solid',
      borderColor: theme.palette.grey['100'],
      background: theme.palette.grey['50'],
      transform: 'translate(-50%, -50%)',
    },
  },
  roomIconShape: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    zIndex: 1,
  },
  defaultRoomIcon: {
    '& $roomIconShape': {
      width: 10,
      height: 64,
      borderRadius: 10,
      background: '#40e0ad',
      marginTop: -32,
      transform: 'translateX(-50%) scale(0.5)',
    },

    '& $roomIconShape:nth-child(1), & $roomIconShape:nth-child(4)': {
      height: 32,
      marginTop: -16,
    },

    '& $roomIconShape:nth-child(1)': {
      marginLeft: -12,
    },

    '& $roomIconShape:nth-child(2)': {
      marginLeft: -4,
    },

    '& $roomIconShape:nth-child(3)': {
      marginLeft: 4,
    },

    '& $roomIconShape:nth-child(4)': {
      marginLeft: 12,
    },
  },
  endedRoomIcon: {
    '& span:nth-child(1)': {
      position: 'absolute',
      top: 12,
      left: 12,
      width: 44,
      height: 44,
      borderRadius: '50%',
      background: theme.palette.grey['300'],

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 15,
        display: 'block',
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: theme.palette.grey['50'],
      }
    },

    '& $roomIconShape:nth-child(2), & $roomIconShape:nth-child(3)': {
      top: 20,
      left: 50,

      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: 4,
        height: 4,
        background: theme.palette.grey['300'],
        transform: 'scaleX(0.85) scaleY(2.5) rotate(45deg)',
      },

      '&:after': {
        transform: 'scaleY(0.85) scaleX(2.5) rotate(45deg)',
      },
    },

    '& $roomIconShape:nth-child(3)': {
      top: 33,
      left: 40,
    },
  },
  brokenRoomIcon: {
    '& $roomIconShape': {
      background: theme.palette.grey['200'],
    },
  },
  audioVisualizer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    width: 76,
    height: 76,
    margin: '-38px 0 0 -38px',
    zIndex: -1,
    borderRadius: '50%',
    opacity: 0.15,
    background: '#40e0ad',
  },
  mainTitle: {
    margin: theme.spacing(0),
  },
  subtitle: {
    ...theme.typography.subtitle1,
    position: 'relative',
    display: 'inline-block',
    margin: theme.spacing(0),
    color: theme.palette.text.secondary,

    '.is-live-room &:before, .is-live-room &:after': {
      content: '""',
      position: 'absolute',
      left: -15,
      top: '50%',
      transform: 'translate(0, -50%)',
      display: 'block',
      width: 8,
      height: 8,
      background: '#ff5258',
      marginRight: '8px',
      borderRadius: '50%',
    },

    '.is-live-room &:after': {
      transform: 'scale(2)',
      background: 'none',
      border: '1px solid #ff5258',
      animation: '$liveRipple 2000ms ease-out infinite',
    },
  },
  speakersList: {
    margin: theme.spacing(2, 0),

    '.is-ended-room &': {
      opacity: 0.25,
    },
  },
  audioPlayer: {
    margin: theme.spacing(4, 'auto', 8),
  },

  '@keyframes liveRipple': {
    '0%': {
      opacity: 1,
      transform: 'translate(0, -50%) scale(1)',
    },
    '30%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
      transform: 'translate(0, -50%) scale(4)',
    },
  },
}));

const Room = ({ roomId }) => {
  const classes = useStyles();

  /* STATE */
  const [roomTitle, setRoomTitle] = useState(null);
  const [readyToRender, setReadyToRender] = useState(false);
  const [visualScale, setVisualScale] = useState(0);
  const [roomStatus, setRoomStatus] = useState(ROOM_STATUS.LIVE);
  const [roomUsers, setRoomUsers] = useState([]);
  const [error, setError] = useState(null);

  /* FUNCTIONS */
  const visualizeAudio = useCallback(({ value }) => {
    setVisualScale(oldVisualScale => Math.max(oldVisualScale - 0.05, value));
  }, []);

  const onLoadRoomDataError = (err) => {
    setReadyToRender(true);
    setError(err);
    setRoomStatus(ROOM_STATUS.BROKEN)
  };

  const loadRoomData = async () => {
    const roomData = await dataService.getRoomData(roomId).catch(onLoadRoomDataError);

    if (roomData) {
      setRoomTitle(roomData.title);
      setRoomStatus(roomData.isLive ? ROOM_STATUS.LIVE : ROOM_STATUS.ENDED)
      setReadyToRender(true);
      agoraService.connect({
        channel: roomId,
        role: 'audience',
        getAudioLevel: (value) => {
          visualizeAudio({ value });
        },
      });
    } else {
      onLoadRoomDataError(new Error());
    }
  }

  /* EFFECTS */
  useEffect(() => {
    loadRoomData();
  }, [roomId]);

  useEffect(() => {
    const socket = dataService.connectToRoom({
      roomId,
      onMessage: ({ route, payload }) => {
        switch (route) {
          case 'CHAT_CLIENT_USERS_LIST':
            setRoomUsers(payload.users.filter(Boolean).map(user => ({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              image: user.photoUrl,
              isSpeaker: payload.speakerIds.includes(user.id),
              activeMic: payload.activeMics[user.id],
            })));
            break;

          case 'CHAT_CLIENT_JOIN_ROOM':
            setRoomUsers((oldRoomUsers) => ([
              ...oldRoomUsers,
              {
                id: payload.user.id,
                name: `${payload.user.firstName} ${payload.user.lastName}`,
                image: payload.user.photoUrl,
                isSpeaker: false,
                activeMic: false,
              },
            ]));
            break;

          case 'CHAT_CLIENT_LEAVE_ROOM':
            setRoomUsers(oldRoomUsers => oldRoomUsers.filter(user => user.id !== payload.userId));
            break;

          case 'CHAT_CLIENT_NEW_SPEAK':
            setRoomUsers(oldRoomUsers => oldRoomUsers.map((user) => {
              if (user.id === payload.listenerUserId) {
                return {
                  ...user,
                  isSpeaker: true,
                };
              }

              return user;
            }));
            break;

          case 'CHAT_CLIENT_REMOVE_SPEAK':
            setRoomUsers(oldRoomUsers => oldRoomUsers.map((user) => {
              if (user.id === payload.speakerUserId) {
                return {
                  ...user,
                  isSpeaker: false,
                };
              }

              return user;
            }));
            break;

          case 'CHAT_CLIENT_UPDATE_MIC':
            setRoomUsers(oldRoomUsers => oldRoomUsers.map((user) => {
              if (user.id === payload.userId) {
                return {
                  ...user,
                  activeMic: payload.activeMic,
                };
              }

              return user;
            }));
            break;

          case 'CHAT_CLIENT_ROOM_ENDED':
            setRoomStatus(ROOM_STATUS.ENDED);
            break;

          default:
        }
      },
    });

    return () => {
      socket.close();
    };
  }, [roomId]);

  /* RENDER */
  const renderRoomIcon = (...classNames) => {
    return (
      <div className={classnames(classes.roomIcon, ...classNames)}>
        <span className={classes.roomIconShape} />
        <span className={classes.roomIconShape} />
        <span className={classes.roomIconShape} />
        <span className={classes.roomIconShape} />

        <span className={classes.audioVisualizer} style={{ transform: `scale(${1 + 0.4 * visualScale})` }} />
        <span className={classes.audioVisualizer} style={{ transform: `scale(${1 + 1.2 * visualScale})` }} />
      </div>
    );
  };

  const renderSpeakersList = () => {
    const speakersList = roomUsers.filter(user => user.isSpeaker);

    return (
      <SpeakersList className={classes.speakersList} data={speakersList} />
    );
  };

  const renderLiveRoom = () => {
    return (
      <div className="is-live-room">
        {renderRoomIcon(classes.defaultRoomIcon)}

        <h2 className={classes.mainTitle}>
          {roomTitle}
        </h2>

        <p className={classes.subtitle}>
          Live now
        </p>

        {renderSpeakersList()}

        <Divider />
      </div>
    );
  };

  const renderOfflineRoom = () => {
    return (
      <div className="is-offline-room">
        {renderRoomIcon(classes.defaultRoomIcon)}

        <h2 className={classes.mainTitle}>
          {roomTitle}
        </h2>

        <p className={classes.subtitle}>
          40 minutes ago
        </p>

        {renderSpeakersList()}

        <AudioPlayer
          className={classes.audioPlayer}
          src="http://localhost:8000/audio.mp3"
          onAudioAnalysis={visualizeAudio}
        />

        <Divider />
      </div>
    );
  };

  const renderEndedRoom = () => {
    return (
      <div className="is-ended-room">
        {renderRoomIcon(classes.endedRoomIcon)}

        <h2 className={classes.mainTitle}>
          This room is over, sorry!
        </h2>

        {renderSpeakersList()}

        <Divider />
      </div>
    );
  };

  const renderBrokenRoom = () => {
    return (
      <div className="is-broken-room">
        {renderRoomIcon(classes.defaultRoomIcon, classes.brokenRoomIcon)}

        <h2 className={classes.mainTitle}>
          Oops! Something went wrong!
        </h2>

        <p className={classes.subtitle}>
          This room cannot be joined right now. Please try again later.
        </p>
      </div>
    );
  };

  const render = () => {
    if (!readyToRender) return null;

    if (error) {
      return renderBrokenRoom();
    }

    switch (roomStatus) {
      case ROOM_STATUS.LIVE:
        return renderLiveRoom();

      case ROOM_STATUS.ENDED:
        return renderEndedRoom();

      case ROOM_STATUS.OFFLINE:
        return renderOfflineRoom();

      default:
        return renderBrokenRoom();
    }
  };

  return (
    <Layout>
      <SEO title={roomTitle} />

      <Container fixed className={classes.roomView}>
        <div>
          {render()}
        </div>
      </Container>
    </Layout>
  )
};

Room.propTypes = {
  roomId: PropTypes.string,
};

Room.defaultProps = {
  roomId: null,
};

export default () => {
  return (
    <Router>
      <Room path="/room/:roomId" />
    </Router>
  );
};
