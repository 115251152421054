import authService from './authService';

export default {
  http(method, url, options) {
    const userData = authService.getUser();
    return (async () => {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${userData.token}`,
          'Content-Type': 'application/json',
        },
        ...options,
      });

      return await response.json();
    })();
  },

  get(url, options) {
    return this.http('get', url, options);
  },

  post(url, options) {
    return this.http('post', url, options);
  },

  async getUserData() {
    const { rooms: activeRooms = [] } = await this.get(process.env.GATSBY_GET_ROOMS_URL);
    const { user } = await this.get(process.env.GATSBY_GET_USER_URL);

    // find if there is a room created by the user or if
    // they are a speaker in any room
    const room = activeRooms.find(r => r.speakerIds.includes(user.id));

    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      photoUrl: user.photoUrl,
      room,
    };
  },

  async getRoomData(roomId) {
    if (!roomId) throw new Error('roomService.loadRoomData expects an `roomId` option.');
    const roomData = await this.get(`${process.env.GATSBY_GET_ROOMS_URL}/${roomId}`);
    const roomStatus = await this.get(`${process.env.GATSBY_GET_ROOM_STATUS_URL}/${roomId}`);
    if (roomData) {
      return {
        ...roomData.room,
        isLive: roomStatus.isLive,
      };
    }
    return false;
  },

  connectToRoom({ roomId, ...options }) {
    if (!roomId) throw new Error('roomService.connectTo expects an `roomId` option.');

    const socket = new WebSocket(process.env.GATSBY_SOCKET_SERVER_URL);

    const onSocketOpen = () => {
      socket.send(JSON.stringify({
        route: 'GUEST_CHAT_JOIN_ROOM',
        payload: {
          roomId,
        },
      }));
    };

    const onSocketMessage = (e) => {
      options.onMessage?.(JSON.parse(e.data));
    };

    const onSocketClose = () => {
      options.onClose?.();
    };

    socket.addEventListener('open', onSocketOpen);
    socket.addEventListener('message', onSocketMessage);
    socket.addEventListener('close', onSocketClose);

    return socket;
  },
};
