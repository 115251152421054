import dataService from './dataService';

export default {
  playAudio(options = {}) {
    if (!options.src) throw new Error('audioService.playAudio expects an `src` option.');

    const audio = new Audio();
    const audioAnalyser = (() => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaElementSource(audio);
      const analyser = audioContext.createAnalyser();

      source.connect(analyser);
      analyser.connect(audioContext.destination);
      analyser.fftSize = 2048;

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteTimeDomainData(dataArray);

      let frame = requestAnimationFrame(function analyseAudio() {
        analyser.getByteTimeDomainData(dataArray);
        frame = requestAnimationFrame(analyseAudio);

        const maxValue = Math.max(...dataArray);
        const normalizedValue = dataArray.reduce((p, n) => p + n / maxValue, 0) / dataArray.length;
        options.onAnalysis?.({ value: 1 - normalizedValue, dataArray });
      });

      return {
        stop: () => {
          cancelAnimationFrame(frame);
        },
      };
    })();

    const onReady = () => {
      options.onReady?.();
    };

    const onProgress = () => {
      options.onProgress?.(audio.buffered);
    };

    const onTimeUpdate = () => {
      options.onTimeUpdate?.(Math.floor(audio.currentTime));
    };

    const onMetaData = () => {
      options.onMetaData?.(Math.floor(audio.duration));
    };

    const onEnded = () => {
      options.onEnded?.();
    };

    audio.addEventListener('canplay', onReady);
    audio.addEventListener('progress', onProgress);
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('loadedmetadata', onMetaData);
    audio.addEventListener('ended', onEnded);
    audio.preload = 'metadata';
    audio.src = options.src;

    return {
      pause: () => audio.pause(),
      play: () => audio.play(),
      seek: time => {
        audio.currentTime = time;
      },
      isEnded: () => audio.ended,
      close: () => {
        audioAnalyser.stop();
        audio.removeEventListener('canplay', onReady);
        audio.removeEventListener('progress', onProgress);
        audio.removeEventListener('timeupdate', onTimeUpdate);
        audio.removeEventListener('loadedmetadata', onMetaData);
        audio.removeEventListener('ended', onEnded);
        audio.pause();
      },
    };
  },

  updateAudioAccess(endpoint, body) {
    return dataService.post(`${process.env.GATSBY_AUDIO_CONTROL_URL}/${endpoint}`, {
      body: JSON.stringify(body),
    });
  },
};
